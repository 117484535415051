<template>
  <mobile-screen :header="true" screen-class="gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="about-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_company-preferences-help-online',
        }"
      >
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "preferences",
              "preferences",
              "company-preferences"
            )
          }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section icon-app1">
      <li
        v-for="category in activeCategories"
        :key="category.id"
        class="clebex-item-section-item full-right-underline has-lead-icon"
      >
        <router-link
          :to="{
            name: 'r_company-preference-category',
            params: { categoryId: category.id },
          }"
        >
          <button class="clebex-item-content-wrapper">
            <span class="lead-icon">
              <button class="lead-icon-item">
                <icon
                  :icon="category.icon"
                  style="width: 100%; height: 20px;"
                />
              </button>
            </span>
            <span class="label">{{ category.name }}</span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </button>
        </router-link>
      </li>
    </ul>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import { errorHandler } from "@/services/error-handler";
import { preferenceCategoriesIconsMap } from "@/services/preference-options";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "Preferences",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
  data() {
    return {
      categories: [],
      preferenceCategoriesIconsMap: preferenceCategoriesIconsMap,
      hiddenPreferenceCategories: [
        "CALENDAR",
        "COLORS",
        "BOOKING",
        "MENU",
        "PUSH_NOTIFICATIONS",
      ],
      helpSlug: "preferences",
    };
  },
  computed: {
    ...mapState("companyPreferences", ["preferenceCategories"]),
    activeCategories() {
      return this.categories.filter(
        (item) => !this.hiddenPreferenceCategories.includes(item.category)
      );
    },
  },
  methods: {
    ...mapActions("companyPreferences", ["getPreferenceCategories"]),
    getCategories() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      this.getPreferenceCategories()
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          this.setCategoryData();
        });
    },
    setCategoryData() {
      this.preferenceCategories.forEach((category, i) => {
        this.categories.push(category);
        this.categories[i].icon =
          preferenceCategoriesIconsMap[category.category];
      });
      this.$store.commit("loader/setScreenLoading", false, { root: true });
    },
  },
  created() {
    this.getCategories();
  },
};
</script>
<style lang="scss" scoped>
.clebex-item-section-item {
  a {
    text-decoration: none;
  }
}
</style>
